.fly-out {
  display: table-caption;
  flex-direction: column;
  padding-left: 1vw;
  background: rgb(28, 56, 121, 0.5);
  overflow: scroll;
  max-height: inherit;
}

/* width */
.fly-out::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.fly-out::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.fly-out::-webkit-scrollbar-thumb {
  background: rgb(28, 56, 121);
}
