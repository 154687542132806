#root
  > div
  > div
  > div.flex-container
  > div:nth-child(1)
  > div:nth-child(2)
  > div.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomRight {
  position: absolute;
  z-index: 100000000000;
}
#root
  > div
  > div
  > div.flex-container
  > div:nth-child(1)
  > div
  > div.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomRight {
  position: absolute;
  z-index: 100000000000;
}

.input-field {
  display: inline-block;
  border: solid 1px black;
  min-width: 10px;
  max-width: 200px;
}

select {
  appearance: none;
}

.dotted-border {
  border-style: dashed !important;
}

.flex-container {
  display: flex;
  height: 100vh;
}

.vertical-flex-container {
  display: flex;
  flex-direction: column;
}
.selection-column {
  background-color: rgb(28, 56, 121);
}
.code-box-column {
  background-color: rgb(249, 245, 235);
}

.vertical-code-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.code-section {
  min-height: 90vh;
  max-height: 90vh;
  overflow-x: scroll;
  overflow-y: scroll;
  background-color: rgb(249, 245, 235);
}

.view-code-section {
  min-height: 90vh;
  max-height: 90vh;
  overflow-x: auto;
  min-width: 48vw;
  max-width: 48vw;
  background-color: #f0f0f0;
}

/* width */

::-webkit-scrollbar {
  height: 5px;
  /* height of horizontal scrollbar */
  width: 0.2vw;
  /* width of vertical scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

/* font for Save File/Run Code text in button */

.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
  font-family: 'Courier New' !important;
}

/* MUI button corner for adding coding blocks */

.MuiButton-root {
  border-radius: 7px !important;
  white-space: nowrap;
}

/* Save File/Run Code button height */

.module-border-wrap {
  /* max-width: 250px; */
  padding: 1vh 0.5vw;
  position: relative;
  background: rgb(245, 242, 240);
}

.body {
  -ms-overflow-style: none;
}

.helpbox {
  background-color: rgb(234, 227, 210);
  padding: 1px solid red;
}

.hljs-keyword {
  font-size: 2.5vh;
}

input[type='view'],
select[type='view'],
option[type='view'],
textarea {
  background-color: rgb(245, 242, 240);
  border-color: rgb(245, 242, 240);
  border: 2px solid rgb(245, 242, 240);
  /*
    if I hide, the indentation would not be correct
    */
}

input.for,
input.if,
input.elif,
input.else,
input.return,
input.print,
input.while,
input.def,
input.break,
input.continue,
input.class,
.lambda,
.in {
  color: rgb(0, 119, 170);
}

.equal {
  color: rgb(154, 110, 58);
}

.token {
  background: inherit !important;
}

.loader {
  z-index: 10000000;
  position: fixed;
  top: 50%;
  left: 50%;
}

.range,
.list,
.map,
.filter,
.len,
.apostrophe {
  color: rgb(102, 153, 0);
}

.bracket,
.colon,
.comma,
.dot,
.curly {
  color: rgb(153, 153, 153);
}

input.comment {
  color: rgb(153, 153, 153);
}

.Collapsible {
  background-color: #00bbff;
}
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}
.Collapsible__trigger {
  display: block;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  border: 1px solid #ebebeb;
  padding: 10px;
  background: #00ac9d;
  color: white;
}

.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: url('./images/angle-small-down.svg');
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  content: url('./images/angle-small-left.svg');
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}
.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}
.CustomTriggerCSS--open {
  background-color: darkslateblue;
}
.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

/* html Table css */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
/*  */

.fa-toggle-on {
  color: #00bbff;
}

.fa-toggle-off {
  color: #00bbff;
}

._3HDv1 {
  white-space: nowrap;
  text-align: center;
}

._3HDv1.read_only {
  background-color: #00bbff;
  color: white;
}
._2iAdU {
  /* hide logo */
  display: none !important;
}

._2Gsmq,
._3r-ro {
  display: none !important;
}

#_2iAdU {
  /* # means select element by id */
  padding: 0 !important;
  display: flow-root !important;
}

iframe {
  /* class type, no . */
  height: 100vh;
  width: 100%;
}

div.sc-ifAKCX {
  max-width: fit-content;
  top: 50%;
  translate: 0px -50%;
}

.transition-drawer-enter {
  opacity: 0;
}
.transition-drawer-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.transition-drawer-exit {
  opacity: 1;
}
.transition-drawer-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.tutor-navbar-small {
  display: none;
}

.tutor-iframe-column {
  flex: 70%;
}

#root
  > div
  > div
  > div:nth-child(1)
  > div
  > div.vertical-flex-container.code-box-column
  > div
  > div:nth-child(2)
  > div.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomRight {
  position: absolute;
  z-index: 100000000000;
}

#_2iAdU > div._2trtS > ul {
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#_2iAdU ._2trtS > ul > li:hover {
  z-index: 1;
}

@media (max-width: 1230px) {
  .tutor-codebox-column {
    max-width: 25vw;
  }
}

@media (max-width: 930px) {
  .tutor-navbar-small {
    display: block;
  }

  .tutor-codebox-column {
    display: none;
  }
  .tutor-iframe-column {
    flex: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .helpbox {
    display: none;
  }
}

@media (max-width: 930px) {
  .tutor-codebox-column #_2iAdU {
    padding: 1rem 0;
    justify-content: space-evenly;
  }

  .tutor-codebox-column #_2iAdU > div {
    margin: 0.5rem 0 0 0;
  }

  .tutor-codebox-column #_2iAdU ._3Kbya {
    display: block;
  }

  .tutor-codebox-column #_2iAdU ._2trtS > ul > li > a {
    display: none;
  }

  .tutor-codebox-column #_2iAdU ._2trtS > ul > li > svg {
    margin: 0;
  }

  .tutor-codebox-column #_2iAdU ._3D598 {
    transform: translateY(99.6%);
    width: 6rem;
  }

  .tutor-codebox-column #_2iAdU ._3D598 > ul > li > a,
  .tutor-codebox-column #_2iAdU ._1JYcn > ul > li > a {
    font-size: 0.5rem;
  }

  .tutor-codebox-column #_2iAdU ._1JYcn {
    width: 6rem;
    transform: translateX(100%) translateY(-9.5%);
  }
  .tutor-codebox-column #_2iAdU > div._2trtS > ul {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

@media (max-width: 930px) {
  .normal-div #_2iAdU {
    padding: 1rem 0;
    justify-content: space-evenly;
  }

  .normal-div #_2iAdU > div {
    margin: 0.5rem 0 0 0;
  }

  .normal-div #_2iAdU ._3Kbya {
    display: block;
  }

  .normal-div #_2iAdU ._2trtS > ul > li > a {
    display: none;
  }

  .normal-div #_2iAdU ._2trtS > ul > li > svg {
    margin: 0;
  }

  .normal-div #_2iAdU ._3D598 {
    transform: translateY(99.6%);
    width: 6rem;
  }

  .normal-div #_2iAdU ._3D598 > ul > li > a,
  .normal-div #_2iAdU ._1JYcn > ul > li > a {
    font-size: 0.5rem;
  }

  .normal-div #_2iAdU ._1JYcn {
    width: 6rem;
    transform: translateX(100%) translateY(-9.5%);
  }
  .normal-div #_2iAdU > div._2trtS > ul {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
