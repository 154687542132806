/* Create two equal columns that floats next to each other */
.column {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  background: rgb(28, 56, 121);
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 100%;
}
.wrapper {
  position: relative;
  max-height: 100%;
}
.parent {
  position: static;
  display: grid;
  padding: 1vh 0.2vw;
}

.parent > .wrapper {
  position: absolute;
  z-index: 1;
  display: none;
  max-height: 30vh;
  
}

.parent .wrapper {
  cursor: auto;
}
.button-item {
  position: static;
  display: inline-grid;
  margin: 0.5vh 0.3vw 0.5vh;
  z-index: 0;
  white-space: normal;
}

.header {
  height: 100px;
  top: 0;
  z-index: 2;
  position: sticky;
  display: flex;
  object-fit: contain;
  background-color: rgb(28, 56, 121);

}

@media (max-width: 700px) {
  .header {
    font-size: medium;
  }
}