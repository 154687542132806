:root {
  --pcol: black;
  --scol: white;
  --tspeed: 0.5s;
  --base-scale: 1.1;
  --subMenu-width: 12.25rem;
  --fgrad: linear-gradient(139deg, #6782b4 16%, #b1bfd8 52%, #6a93cb 88%);
  --sgrad: linear-gradient(
    139deg,
    rgba(51, 51, 51, 1) 16%,
    #5d4954 76%,
    rgba(51, 51, 51, 1) 100%
  );
  --rm-font-size: 0.875rem;
  --sm-font-size: 0.65rem;
}
