.fab {
  position: absolute;
  top: 0%;
  right: 0%;
  margin: 0.5em;
  opacity: 60%;
}

.fab:hover {
  opacity: 100%;
}
